<template>
	<div>
		<van-steps :active="step_active" active-icon="success" active-color="#ff976a">
			<van-step>{{translate('success_option')}}</van-step>
			<van-step>{{translate('success_amount_of_deposit')}}</van-step>
			<van-step>{{translate('check_of_notice')}}</van-step>
		</van-steps>

		<div style="text-align: center;padding: 30px 10px;">
			<van-icon name="checked" size="110" color="orange"/>
			
			<p style="margin: 20px 0 80px 0;">{{translate('submitted_successfully_wait')}}</p>
			
		</div>

		<div class="common_block">
			<p style="color: blue;text-align: center" @click="goHistory">{{translate('success_gold_entry_record')}}</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'rssdeposit_success',
		data() {
			return {
				step_active: 2,

				token: "",
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.token = this.$route.query.token
			}
		},
		methods: {
			goHistory() {
				this.$router.push('/rssdeposit/history?token=' + this.token)
			}
		},
	}
</script>